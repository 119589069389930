// Pretop - Table version
// ================================================================

section.pretop {
	background-color: $pretop-bg-color;
	border-bottom: 1px solid mix($light-gray,black,90);
	> .row {
		height: $pretop-height;
	}
	ul.pretop--list {
		margin: 0;
		list-style: none;
		> li {
			display: inline-block;
		}
		> li a {
			text-decoration: none;
			display: block;
			text-transform: uppercase;
			font-size: rem-calc(13);
			padding: 0 20px;
			color: $medium-gray;
			border-left: 1px solid mix($light-gray,black,90);
			span {
				margin-left: 8px;
			}
		}
	}
}