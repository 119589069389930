// Globals
//-------------------------------------------------------------------------------------
$breakpoint: large;

// Custom Colors
//-------------------------------------------------------------------------------------
$facebook-color: #3B5998;
$messenger-color: #339CFF;
$twitter-color: #55ACEE;
$google-plus-color: #DD4B38;
$linkedin-color: #0077B5;
$pinterest-color: #D72329;
$rss-color: #ED7B08;
$vimeo-color: #44BBFF;
$link-color: $primary-color;

// Pretop
//-------------------------------------------------------------------------------------

$pretop-bg-color: $white;
$pretop-height: 45px;
$pretop-height-small: 45px;

// Top
//-------------------------------------------------------------------------------------

$top-bg-color: $white;
$top-height: 90px;
$top-height-small: 70px;
$top-center-width: 0;

$logo-width: 70px;
$logo-width-small: 50px;

$burger-color: $secondary-color;
$burger-color-active: $burger-color;
$burger-width: 22px;
$burger-size: 16px;
$burger-weight: 3px;

$top-icon-color: $burger-color;

// Header
//-------------------------------------------------------------------------------------

$header-bg-color: $dark-gray;
$header-height: 350px;
$header-height-max: 100vh;
$header-height-small: 200px;

// Navigation
//-------------------------------------------------------------------------------------
$nav-margin: 0 auto; // center, left og right aligned in column
$nav-family: $header-font-family;
$nav-text-transform: uppercase;
$nav-font-size: rem-calc(14);
$nav-padding: 0 20px;
$nav-letter-spacing: 0;
$nav-divider: transparent; //1px solid $light-gray;


	// Normal
	$nav-color: $dark-gray;
	$nav-font-weight: 600;

	// Active
	$nav-color-active: $primary-color;
	$nav-font-weight-active: 600;
	$nav-background-active: $light-gray;

	// Hover and focus
	$nav-color-hover: $primary-color;
	$nav-background-hover: $white;

	// Sublevels
	$nav-sublevel-bg: $white;
	$nav-sublevel-bg-hover: $white;
	$nav-sublevel-bg-active: $white;
	$nav-sublevel-title: #6F6F6F;
	$nav-sublevel-color: #6F6F6F;
	$nav-sublevel-color-hover: $primary-color;
	$nav-sublevel-color-active: $primary-color;
	$nav-sublevel-border: 1px solid rgba(black,0.1);
	$nav-sublevel-text-transform: uppercase;
	$nav-sublevel-font-size: rem-calc(14);
	$nav-sublevel-font-weight: 400;

// Custom Button Style
//-------------------------------------------------------------------------------------

$button-font-weight: 400;
$button-letter-spacing: 0em;
$button-text-transform: uppercase;

// Menu Flex
//-------------------------------------------------------------------------------------

$menu-flex-bg-color: $light-gray;
$menu-flex-bg-color-hover: $black;
$menu-flex-color: $white;
$menu-flex-color-hover: rgba($white,0.75);
$menu-flex-padding: 50px 20px;
$menu-flex-padding-small: 30px 0;
$menu-flex-item-width-small: 100%;

// Pagesizes
//-------------------------------------------------------------------------------------

$band-padding: $global-margin * 5;
$wide-width: 1400px;

// Bottom
//-------------------------------------------------------------------------------------

$bottom-bg-color: lighten($dark-gray,5%);
$bottom-color: rgba(white,0.5);
$bottom-header-color: rgba(white,0.5);
$bottom-link-color: $white;
$bottom-icon-color: rgba(white,0.5);

// Footer
//-------------------------------------------------------------------------------------

$footer-bg-color: $dark-gray;
$footer-padding: 2rem 0;
$footer-color: rgba(white,0.5);
$footer-header-color: $footer-color;
$footer-link-color: $footer-color;

// Breadcrumbs
//-------------------------------------------------------------------------------------

$breadcrumbs-bg-color: $light-gray;



// Typography
//-------------------------------------------------------------------------------------
$headers: h1,h2,h3,h4,h5,h6;