// SHADOW
// ================================================================

@mixin shadow($img:shadow-in,$o:0.2) {
    position: relative;
    &:before {
    content: "";
    position: absolute;
    width: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
    padding-top: 10%;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    top: 0;
	background-image: url("../img/globals/#{$img}.png");
	opacity: $o;
    }
}