/* Blocks by Absoluttweb
================================================================ */

// Variables----------------------------------------------------
@import "variables";

// Mixins-------------------------------------------------------
@import "mixins/mixins";
@import "mixins/divider";
@import "mixins/button";
@import "mixins/color";
@import "mixins/gradient";
@import "mixins/header";
@import "mixins/overlay";
@import "mixins/section";
@import "mixins/shadow";

// partials -> layout-sections------------------------------------------
@import "partials/layout-sections/pretop";
@import "partials/layout-sections/top";
@import "partials/layout-sections/header";
@import "partials/layout-sections/bottom";
@import "partials/layout-sections/footer";

// basic partials-------------------------------------------------------
@import "partials/base";
//@import "partials/menu-flex";
@import "partials/menu-mega";
@import "partials/shortcodes";
//@import "partials/snow";
@import "partials/totop";
